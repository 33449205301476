<template>
<div id="app" class="app">
   <topheader/>
   <mainheader/>
    <div id="content" class="app-content">
    <router-view/>
    </div>
</div>
</template>
<script>
import mainheader from './includes/mainheader.vue'
import topheader from './includes/topheader.vue'
export default {

components:{
    mainheader,
    topheader
},



mounted(){
      var scripts = [
        "/assets/js/app.min.js",
        "/assets//js/vendor.min.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })
  },
    
}
</script>
<style>
.hideall{
    display:none !important
}
</style>