<template>
    <div id="sidebar" class="app-sidebar" style="background-color:#11192A !important">

<div class="app-sidebar-content" data-scrollbar="true" data-height="100%" style="background-color:#11192A !important">

<div class="menu">
<div class="menu-header" style="color:#fff" >Navigation</div>


<div class="menu-item">
<router-link :to="{name:'welcome'}" class="menu-link">
<span class="menu-icon" style="color:#fff"><i class="fas fa-desktop"></i></span>
<span class="menu-text" style="color:#fff">Dashboard</span>
</router-link>
</div>

<div class="menu-divider"></div>
<div class="menu-header" style="color:#fff">Essentials</div>

<div class="menu-item ">
  <router-link :to="{name:'searchlocations'}" class="menu-link">
  <span class="menu-icon" style="color:#fff"><i class="fas fa-search"></i></span>
  <span class="menu-text" style="color:#fff">Search Locals</span>
  </router-link>
  </div>

<div class="menu-item ">
<router-link :to="{name:'locations'}" class="menu-link">
<span class="menu-icon" style="color:#fff"><i class="fas fa-globe"></i></span>
<span class="menu-text" style="color:#fff">Locations</span>
</router-link>
</div>

<div class="menu-item ">
<router-link :to="{name:'industries'}" class="menu-link">
<span class="menu-icon" style="color:#fff"><i class="fas fa-hospital"></i></span>
<span class="menu-text" style="color:#fff">Industries</span>
</router-link>
</div>


<div class="menu-item ">
<router-link :to="{name:'professions'}" class="menu-link">
<span class="menu-icon" style="color:#fff"><i class="fas fa-graduation-cap"></i></span>
<span class="menu-text" style="color:#fff">Professions</span>
</router-link>
</div>


<div class="menu-divider" style="color:#fff"></div>
<div class="menu-header" style="color:#fff">Me</div>

<div class="menu-item ">
<a class="menu-link" @click="logmewoutuser">
<span class="menu-icon" style="color:#fff"><i class="fas fa-power-off"></i></span>
<span class="menu-text" style="color:#fff">Log out</span>
</a>
</div>







</div>

</div>


<button class="app-sidebar-mobile-backdrop" data-dismiss="sidebar-mobile"></button>

</div>



</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default{


    computed:{
        ...mapGetters(['authuser'])
    },


    methods:{
      ...mapActions(['logoutuser']),

      logmewoutuser(){
        this.logoutuser().then(()=>{

        })
      }

    },

    mounted(){
      var scripts = [

        "/assets/plugins/jquery/js/jquery.min.js",

    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })
  },
}
</script>


